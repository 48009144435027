<template>
  <div>
    <Title title="Student FAQ" subtitle="Frequently Asked Questions" />
    <DocumentList :documentListItems="studentFaqListItems" />
  </div>
</template>

<script>
import Title from '@/components/Title.vue'
import DocumentList from '@/components/DocumentList.vue'

export default {
  components: {
    Title,
    DocumentList
  },
  data () {
    return {
      studentFaqListItems: [
        { router: false, documentLink: '/how-to-apply-for-arn', documentName: 'How do I apply for an Aviation Reference Number (ARN)?' },
        { router: false, documentLink: '/how-to-apply-for-raaus', documentName: 'How do I apply for membership to Recreational Aviation Australia (RA-Aus)?' },
        { router: false, documentLink: '/how-to-apply-for-medical', documentName: 'How do I apply for an aviation medical?' },
        { router: false, documentLink: '/what-are-medical-obligations', documentName: 'What are my obligations once I have a medical certificate?' },
        { router: false, documentLink: '/how-to-check-weather', documentName: 'How do I check the weather before my flight lesson?' },
        { router: false, documentLink: '/how-to-find-air-legislation', documentName: 'How do I find air legislation?' },
        { router: false, documentLink: '/how-to-find-documentation', documentName: 'How do I find documentation such as AIP, ERSA, VFRG, charts and aircraft manuals?' },
        { router: false, documentLink: '/how-to-purchase-aviation-headset', documentName: 'How do I purchase an aviation headset?' },
        { router: false, documentLink: '/how-to-manage-currency', documentName: 'I have flight reviews and medical renewals and other scheduled currencies - how do I manage this?' },
        { router: false, documentLink: '/how-to-weight-balance', documentName: 'How do I perform a Weight and Balance check before my flight?' },
        { router: false, documentLink: '/how-to-todr-ldr', documentName: 'How do I perform a Take-off and Landing Distance Required check before my flight?' },
        { router: false, documentLink: '/how-to-submit-update', documentName: 'I have found an issue or improvement for this website - how do I submit this?' }
      ]
    }
  }
}
</script>
